<template>
  <div class="page" style="max-width: 600px; margin: 0 auto;">
    <div class="" style=" padding: 20px;">
      <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 50 }" placeholder="请输入就业指导内容" v-model="FormData.content_txt">
      </el-input>
      <div style="text-align: right;">{{ FormData.content_txt.length }}/200</div>
      <div style="padding: 20px 0;" v-if="!FormData.sp_status||FormData.sp_status==3">
        <el-button style="width: 45%;" @click="saveForm(0)" :loading="loading">
          保存草稿
        </el-button>
        <el-button type="primary" style="width: 45%;" @click="saveForm(1)" :loading="loading">
          提交审核
        </el-button>
      </div>

      <div>
        <span v-if="FormData.sp_status==1" style="color: cornflowerblue;">已提交，待审核</span>
        <span v-if="FormData.sp_status==2" style="color: greenyellow;">审核通过</span>
        <span v-if="FormData.sp_status==3" style="color: orangered;">审核不通过，{{ FormData.sp_word }}</span>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      
      loading: false,
      FormData:{
        content_txt:""
      }

    }
  },
  mounted() {
    document.title = "就业指导"
    this.getList()
  },
  methods: {

    getList() {
      this.$http.post("/api/jyzd_info").then(res => {
        if (res.data) {
          this.FormData = res.data
     
        }
      })
    },
    saveForm(st) {
      if (this.FormData.content_txt) {
        this.FormData.sp_status = st
        this.loading = true
        this.$http.post("/api/jyzd_edit",  this.FormData).then(res => {
          if (st == 0) {
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success',
              duration:1000
            });
          } else {
            this.$notify({
              title: '成功',
              message: '提交成功',
              type: 'success',
              duration:1000

            });
          }
          this.loading  = false
          this.getList()
        })
      }
    }

  }
}
</script>

<style scoped>
.inputarea {
  width: 90%;
  height: 200px;
  border: 1px solid #eee;
}
</style>
